<template>
  <div class="flex flex-col">
    <IntroBloc />
    <GetBestOfferBloc />
    <OurServicesBloc />
    <TestimonialsBloc />
    <div
      class="flex flex-col bg-cover bg-no-repeat bg-promy-gray-50"
      style="background-image: url(/images/shape-wave-footer.svg)"
    >
      <CompareOffersBloc />
      <Footer textColor="text-white" />
    </div>
  </div>
</template>
<script>
export default {
  created() {
    document.title =
      'Révélez le potentiel de votre bien / Vendez votre bien aux meilleures conditions.'
  },
}
</script>
